/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.0.0-v202409202359-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqDisplayTemplatesApiClass {
 
   constructor() {}

  /**
   * @summary Archive a display template
   * @param {string} id - ID of the display template to archive.
   */
  public archiveDisplayTemplate(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/display-templates/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Create a new template item for a display
   */
  public createDisplayTemplate(
    body: models.DisplayTemplateInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/display-templates`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.DisplayTemplateOutputV1>;
  }

  /**
   * @summary Get a display template item
   * @param {string} id - The Seeq ID for the display template
   */
  public getDisplayTemplate(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/display-templates/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.DisplayTemplateOutputV1>;
  }

  /**
   * @summary Get a list of display template items
   * @param {number} [offset=0] - The pagination offset, the index of the first display template item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of display template items that will be returned in this page of results
   */
  public getDisplayTemplates(
    {
      offset,
      limit
    } : {
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/display-templates`,

      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.DisplayTemplateOutputListV1>;
  }

  /**
   * @summary Update a template item for a display
   * @param {string} id - The Seeq ID for the display template
   */
  public updateTemplate(
    body: models.DisplayTemplateInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/display-templates/${encodeURIComponent(String(id))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.DisplayTemplateOutputV1>;
  }

}


export const sqDisplayTemplatesApi = new sqDisplayTemplatesApiClass();
